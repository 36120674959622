import React, { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react'
import { defaultLocale, Locale, localesPaths } from './Locale'

export interface LocaleContextValue {
  locale: Locale
  pathWithoutLocale: string
}

export const LocaleContext = createContext<LocaleContextValue>({
  locale: defaultLocale,
  pathWithoutLocale: '/',
})

interface LocaleContextProps {
  locale: Locale
  currentPath: string
}

export function useLocaleContextValue({
  locale,
  currentPath,
}: LocaleContextProps): LocaleContextValue {
  const localePath = localesPaths.get(locale)!
  const pathWithoutLocale =
    localePath !== '/' && currentPath.startsWith(localePath)
      ? currentPath.replace(localePath, '/')
      : currentPath

  return useMemo(() => ({ locale, pathWithoutLocale }), [locale, pathWithoutLocale])
}

export function useLocale(): Locale {
  return useContext(LocaleContext).locale
}

export const LocaleProvider: FC<PropsWithChildren & LocaleContextProps> = ({
  children,
  locale,
  currentPath,
}) => (
  <LocaleContext.Provider value={useLocaleContextValue({ locale, currentPath: currentPath })}>
    {children}
  </LocaleContext.Provider>
)
