export enum Locale {
  En = 'en',
  It = 'it',
  Ru = 'ru',
  De = 'de',
  Es = 'es',
}

export const allLocales = Object.values(Locale)
export const contentLocales = [Locale.En, Locale.Ru]
export const defaultLocale = Locale.En
export const localesPaths = new Map<Locale, string>(
  allLocales.map((locale) => [locale, locale === defaultLocale ? '/' : `/${locale}/`]),
)

const multipleSlashRegexp = /\/{2,}/g
export const concatPaths = (...paths: string[]): string =>
  paths.join('/').replace(multipleSlashRegexp, '/')
export const localeTitles: { [key in Locale]: string } = {
  [Locale.En]: 'English',
  [Locale.Ru]: 'Русский',
  [Locale.De]: 'Deutsch',
  [Locale.Es]: 'Español',
  [Locale.It]: 'Italiano',
}
