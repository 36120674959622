import React, { createContext, FC, PropsWithChildren, useContext } from 'react'

export type MessagesContextValue = Queries.Messages

export const MessagesContext = createContext<MessagesContextValue>({} as MessagesContextValue)

export const MessagesProvider: FC<PropsWithChildren & { messages: Queries.Messages }> = ({
  messages,
  children,
}) => <MessagesContext.Provider value={messages}>{children}</MessagesContext.Provider>
