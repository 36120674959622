import React from 'react'
import { LocaleProvider } from './src/common/intl/LocaleContext'
import { GatsbyBrowser, WrapPageElementBrowserArgs } from 'gatsby'
import { Locale } from './src/common/intl/Locale'
import { PageContext } from 'gatsby/internal'
import './src/styles/global.css'
import { MessagesProvider } from './src/common/intl/MessagesContext'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}: WrapPageElementBrowserArgs<any, PageContext>) => {
  return (
    <LocaleProvider
      locale={props.pageContext.locale || Locale.En}
      currentPath={props.location.pathname}
    >
      <MessagesProvider messages={props.data?.messages}>{element}</MessagesProvider>
    </LocaleProvider>
  )
}
