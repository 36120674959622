import * as Sentry from '@sentry/gatsby'
import { PRODUCTION } from './src/env'

Sentry.init({
  dsn: 'https://dc7c7e5eb3f8759a1b2aad01d4514999@o99250.ingest.sentry.io/4506328620793856',
  integrations: [new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: PRODUCTION,
  allowUrls: ['https://webcammictest.com'],
})
